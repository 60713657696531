import dynamic from "next/dynamic";
import { useSelector } from "react-redux";

const GoogleWidget = dynamic(() => import('../HomeComponent/GoogleWidget'), { ssr: true })
const EssaWidget = dynamic(() => import('../HomeComponent/EssaWidget'), { ssr: true })

const FooterAccreditation = ({ type }) => {
    const template_settings = useSelector(state => state.globalReducer.template_settings);

    return (
        <div className={`${type == 'mobile' ? 'd-flex' : ''}`}>
            {template_settings['footer_show_trustpilot'] == 'yes' && <GoogleWidget />}
            <EssaWidget type='mobile' />
        </div>
    )
}

export default FooterAccreditation
